import { GoogleMap, LoadScript } from '@react-google-maps/api';
export const GOOGLE_MAPS_API = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const containerStyle = {
  width: '100vw',
  height: '100vh',
};

const center = {
  lat: 46.356,
  lng: 6.5641,
};

const mapOptions = {
  disableDefaultUI: true,
  zoomControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
  streetViewControl: false,
};

export const Map = () => {
  return (
    <div className="map-container">
      <LoadScript googleMapsApiKey = {GOOGLE_MAPS_API}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={11}
          options={mapOptions}
        >
          {/* Add markers or any additional components here */}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};