import "./Breadcrumb.css"

export default function Breadcrumb() {
  return (
    <div className='breadcrumb'>
      <div className='breadcrumb-navigation'>
        <button className='breadcrumb-map-button'>
          <div className='icon' />
          <span className='breadcrumb-label'>Map</span>
        </button>
        <button className='breadcrumb-list-button'>
          <span className='breadcrumb-label-list'>List</span>
        </button>
      </div>
      <button className='breadcrumb-date-button'>
        <span className='breadcrumb-date'>04/07/2024</span>
        <div className='breadcrumb-chevron-down'>
          <div className='breadcrumb-icon-date' />
        </div>
      </button>
      <button className='breadcrumb-time-button'>
        <span className='breadcrumb-time'>9:00 - 19:00</span>
        <div className='breadcrumb-chevron-time'>
          <div className='breadcrumb-icon-time' />
        </div>
      </button>
      <button className='breadcrumb-teams-button'>
        <span className='breadcrumb-teams'>All Teams</span>
        <div className='breadcrumb-chevron-teams'>
          <div className='breadcrumb-icon-teams' />
        </div>
      </button>
      <button className='breadcrumb-agents-button'>
        <span className='breadcrumb-agents'>All Agents</span>
        <div className='breadcrumb-chevron-agents'>
          <div className='breadcrumb-icon-agents' />
        </div>
      </button>
      <button className='breadcrumb-create-task-button'>
        <span className='breadcrumb-create-task'>Créer une tâche</span>
      </button>
    </div>
  );
}
