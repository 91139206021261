import Header from './Header/Header';
import Breadcrumb from './Breadcumb/Breadcrumb';
import {Map} from './google_map';
import List from './List_item/ListItem';
import React , { Fragment } from 'react'

const Dashboard = () => {
    return (
    <Fragment>
        <Header />
        <Breadcrumb />
        <Map />
        <List />
    </Fragment>
    );
  }
  
  export default Dashboard;