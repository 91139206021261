import "./Header.css";

export default function Header() {
  return (
    <div className='header'>
      <div className='flyspa-logo' />
      <button className='avatar'>
        <div className='avatar-background' />
        <span className='avatar-initial'>AL</span>
      </button>
      <span className='header-title'>Dashboard</span>
      <div className='icon-notif' />
      <div className='icon-small' />
    </div>
  );
}
